import { api } from './api.service'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import { useParams } from 'react-router-dom';
import { GeneratedDoc } from './generated-doc';
import Button from './components/button';
import ReactToPrint from 'react-to-print';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="m-10">
      <GeneratedDoc {...props}></GeneratedDoc>
    </div>

  );
});

export default function AdminViewInduction() {
  const componentRef = useRef();
  const [data, setData] = useState();
  const [config, setConfig] = useState();
  const [steps, setSteps] = useState();
  const params = useParams();
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/config/induction/${params.id}`).then(x => {
      setData(x);
      setSteps(x.configurationSnapshot?.steps);
      setConfig(x.configurationSnapshot?.config);
    });
    // api(`${process.env.REACT_APP_API_URL}/induction/${params.config}`).then(x => {
    //   setSteps(x.steps);
    //   setConfig(x.configuration);
    // });
  }, [])
  if (!config || !steps || !data) return <></>
  return (
    <>
      <div className="flex px-4 justify-between max-w-6xl mx-auto"><Button link="/admin" text="Back"></Button>
      <div>
      <ReactToPrint trigger={() => {
          return <Button text="Print Document"></Button>
        }} content={() => componentRef.current} />
      </div>
        
        </div>
      <div className="flex flex-col w-full mb-10">
        <ComponentToPrint ref={componentRef} config={config} steps={steps} values={data.values} signature={data.signature} readOnly={true} />
      </div>

    </>
  )
}