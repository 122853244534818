import { useState } from "react";
import { api_file_no_auth } from "../api.service";

export const FileUpload = ({ success, multiple, hideTitle }) => {

    const [loading, setLoading] = useState(false);

    const submit = (files) => {
        setLoading(true);
        api_file_no_auth(`${process.env.REACT_APP_API_URL}/file`, files).then(x => {
            success(x.data);
            setLoading(false);
        });
    }

    if (loading) return <div>Uploading File...</div>

    return <label>
        {!hideTitle && <div className="block text-sm font-medium text-neutral-700 mt-2">
            Upload {multiple ? 'Files' : 'New File'}
        </div>}
        <div className="cursor-pointer justify-center inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            Upload
        </div>
        <input multiple={multiple} onChange={(e) => {submit(e.target.files); e.target.value = null}} type="file" className="opacity-0 w=0 h-0"></input>
    </label>

}