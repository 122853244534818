import { useEffect, useState } from "react";

const Image = (props) => {

    const [img, setImg] = useState();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_FILE_URL}/${props.url}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }).then(x => x.blob()).then(x => setImg(URL.createObjectURL(x)))
    }, [])
    if (img) return <img {...props} src={img} className="max-h-96 w-auto object-contain"></img>
    return 'Loading...'
}

export const GeneratedDoc = ({ config, steps, values, readOnly, signature, editClick }) => {
    return <div>
        {!readOnly && <span className="cursor-pointer float-right text-sm text-neutral-600 underline" onClick={() => editClick(-1)}>edit</span>}
        <div className="mt-1">
            <label className="block text-sm font-medium text-neutral-700 mt-2">
                Full Name
            </label>
            <div className="text-neutral-800 text-lg font-semibold">{values.name ?? '---'}</div>
        </div>
        <div className="mt-1">
            <label className="block text-sm font-medium text-neutral-700 mt-2">
                Email Address
            </label>
            <div className="text-neutral-800 text-lg font-semibold">{values.email ?? '---'}</div>
        </div>
        {config.metaDataFields?.map(x => <div className="mt-1">
            <label className="block text-sm font-medium text-neutral-700 mt-2">
                {x}
            </label>
            <div className="text-neutral-800 text-lg font-semibold">{values?.metaData ? values?.metaData[x] : '---'}</div>
        </div>)}

        <div className="my-3 border-b border-neutral-400" />

        {steps.map((step, index) => <>
            <div className="mb-5">
                {!readOnly && <span className="cursor-pointer float-right text-sm text-neutral-600 underline" onClick={() => editClick(index)}>edit</span>}
                {step.type == "0" && <>
                    {step.questions?.map((x, i) => <div className="my-3">
                        <span className="text-neutral-700 text-lg font-semibold">{x.title}</span>
                        <div className="text-neutral-500 text-sm">{x.details}</div>
                        {x.responseType === "yesno" && <>
                            <div className="text-neutral-800 text-lg font-semibold"> {values[index + "_" + i] ? 'Yes' : 'No'}</div>

                        </>}
                        {x.responseType === "freetext" && <>
                            <div className="text-neutral-800 text-lg font-semibold">{values[index + "_" + i] ?? '---'}</div>
                        </>}
                        {x.responseType === "select" && <>
                            <div className="text-neutral-800 text-lg font-semibold">{x.responseOptions.find(x => x.value == values[index + "_" + i])?.text ?? '---'}</div>
                        </>}
                        {x.responseType === "date" && <>
                            <div className="text-neutral-800 text-lg font-semibold">{values[index + "_" + i] ? new Date(values[index + "_" + i]).toDateString() : '---'}</div>
                        </>}
                        <hr className="my-3" />
                    </div>)}

                </>}
                {step.type == "1" && <>
                    <div className="text-neutral-500 text-sm mt-5">I confirm I've read the provided document</div>
                </>}
                {step.type == "2" && <>
                    <div className="text-neutral-500 text-sm mt-5">I confirm I've watched the provided video</div>
                </>}
                {step.type == "3" && <>
                    <div className="text-neutral-500 text-sm">{step.photoUploadInstructions}</div>
                    <div className="flex flex-col">
                        {values[index + "_files"]?.map(x => <>
                            <Image className="mx-auto my-5" url={x}></Image>
                        </>)}
                    </div>
                </>}
            </div>
            <div className="my-3 border-b border-neutral-400" />
        </>)}

        {readOnly && signature && <>

            <img className="h-auto w-64" src={signature.data}></img>
            {new Date(signature.date).toUTCString()}
        </>}
    </div>
}