import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import { Link } from 'react-router-dom';
import Button from './components/button';
import Confirm from './components/confirm';

const objectSingle = "induction";
const objectMulti = "inductions";

export default function AdminInductions() {

  const [data, setData] = useState();
  const [configurations, setConfigurations] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState()
  const [selectedCells, setSelectedCells] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/config/induction/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config/inductions/${selectedConfig._id}`).then(x => {
        setData(x);
        setDeleteId(null)
      });
    });
  }

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/config`).then(x => {
      setConfigurations(x);
    });
  }, [])

  const selectConfig = (config) => {
    setSelectedConfig(config)
    api(`${process.env.REACT_APP_API_URL}/config/inductions/${config._id}`).then(x => {
      setData(x);
    });
  }

  const download = (id, name) => {
    fetch(`${process.env.REACT_APP_API_URL}/config/generate-file/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${name} Induction.pdf`,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  }
  const downloadMultiple = () => {
    fetch(`${process.env.REACT_APP_API_URL}/config/generate-files`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),

      },
      body: JSON.stringify({ ids: selectedCells })
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Inductions.zip`,
        );

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  }


  return (
    <>
      <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
      <div>

        <div className="px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          {data && <Button className="mb-5" onClick={() => { setData(false); setSelectedConfig(false) }} text="Back"></Button>}
          {selectedCells && selectedCells.length > 0 && <Button className="float-right" onClick={() => downloadMultiple()} text="Download Selected"></Button>}
          <h2 className="capitalize flex-1 mx-auto text-lg font-medium leading-6 text-zinc-900 ">
            {objectMulti}
          </h2>
        </div>


        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              {!data && <Table data={configurations.map(x => { return { ...x, view: <span className="cursor-pointer underline" onClick={() => selectConfig(x)}>View Inductions</span> } })} columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'View',
                  accessor: 'view',
                },
              ]}></Table>}

              {data && <Table exportColumns={['Name', 'Email', 'Date Of Induction']} exportData={data.map(x => { return { name: x.name, email: x.email, date: x.signature.date ? new Date(x.signature.date).toDateString() : '' } })} data={data.map(x => {
                return {
                  ...x, view: <Link to={`/admin/induction/${x._id}/${selectedConfig._id}`} className="underline" >View</Link>,
                  download: <span className="cursor-pointer underline" onClick={() => download(x._id, x.name)}>Download PDF</span>,
                  checkboxCell: <input type="checkbox" checked={selectedCells.includes(x._id)} onChange={(e) => !e.target.checked ? setSelectedCells(selectedCells.filter(y => y != x._id)) : setSelectedCells(selectedCells.concat([x._id]))}></input>,
                  deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>,
                  date: x.signature.date ? new Date(x.signature.date).toDateString() : ''
                }
              })} columns={[
                {
                  Header: <input type="checkbox" checked={selectedCells.length == data.length} onChange={(e) => !e.target.checked ? setSelectedCells([]) : setSelectedCells(data.map(x => x._id))}></input>,
                  accessor: 'checkboxCell',
                },
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                },
                {
                  Header: 'Date Of Induction',
                  accessor: 'date',
                },
                {
                  Header: 'View',
                  accessor: 'view',
                },
                {
                  Header: 'Download',
                  accessor: 'download',
                },
                {
                  Header: 'Delete',
                  accessor: 'deleteCell',
                },
              ]}></Table>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

