import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import { useParams } from 'react-router-dom';
import { FileUpload } from './components/file-upload';
import Button from './components/button';
import Confirm from './components/confirm';

const objectSingle = "submit step";
const objectMulti = "submit steps";

export default function SubmitSteps() {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/config/submit-step/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config/submit-steps/${params.id}`).then(x => {
        setData(x);
        setDeleteId(null)
      });
    });
  }

  const params = useParams()

  const submit = (values) => {
    api(`${process.env.REACT_APP_API_URL}/config/submit-step/${params.id}`, { step: values }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config/submit-steps/${params.id}`).then(x => {
        setData(x);
      });
    });
  }
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/config/submit-steps/${params.id}`).then(x => {
      setData(x);
    });
  }, [])

  return (
    <>
      <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
      {edit && <EditModal close={() => setEdit(null)} submit={submit} edit={edit}></EditModal>}
      <div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto"><Button className="mb-5" link="/admin/config" text="Back"></Button></div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div>
            <h2 className="capitalize flex-1 mx-auto text-lg font-medium leading-6 text-zinc-900 ">
              {objectMulti}
            </h2>
            <p className="text-sm text-neutral-500">These are the automatic steps that are completed once an induction is submitted. You can use this to call APIs for external systems or upload the completed document to an external file server.</p>

          </div>
          <div className="w-96">
            <button
              onClick={() => setEdit({ name: '' })}
              type="button"
              className="float-right capitalize inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Add {objectSingle}
            </button>
          </div>


        </div>

        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              <Table data={data.map(x => {
                return {
                  ...x,
                  edit: <span className="cursor-pointer underline" onClick={() => setEdit(x)}>Edit</span>,
                  deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>,
                  typeName: x.type == "0" ? 'Webhook' : x.type == "1" ? 'Save to SFTP' : ''
                }
              })} columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'Type',
                  accessor: 'typeName',
                },
                {
                  Header: 'Edit',
                  accessor: 'edit',
                },
                {
                  Header: 'Edit',
                  accessor: 'deleteCell',
                },
              ]}></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditModal = ({ close, submit, edit }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    if (edit) {
      setValues(edit)
    }
  }, [])

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="w-full">
                  <h1 className="capitalize text-lg font-semibold text-neutral-900 text-center">
                    Manage {objectSingle}
                  </h1>
                  <div className="py-10 px-4 sm:px-6">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Name
                          </label>
                          <input
                            value={values.name}
                            onChange={(e) => setValues({ ...values, name: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label className="block text-sm font-medium text-zinc-700">
                          Type
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            value={values.type}
                            onChange={(e) => setValues({ ...values, type: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                          >
                            <option value=""></option>
                            <option value="0">Webhook</option>
                            <option value="1">Save PDF to SFTP</option>
                          </select>
                        </div>
                      </div>

                      {values.type == "0" && <>
                        <div className="sm:col-span-6">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              API URL
                            </label>
                            <input
                              value={values.details?.url}
                              onChange={(e) => setValues({ ...values, details: values.details ? { ...values.details, url: e.target.value } : { url: e.target.value } })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </>}

                      {values.type == "1" && <>
                        <div className="sm:col-span-3">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              SFTP Host
                            </label>
                            <input
                              value={values.details?.host}
                              onChange={(e) => setValues({ ...values, details: values.details ? { ...values.details, host: e.target.value } : { host: e.target.value } })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              SFTP Port
                            </label>
                            <input
                              value={values.details?.port}
                              onChange={(e) => setValues({ ...values, details: values.details ? { ...values.details, port: e.target.value } : { port: e.target.value } })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              SFTP Username
                            </label>
                            <input
                              value={values.details?.username}
                              onChange={(e) => setValues({ ...values, details: values.details ? { ...values.details, username: e.target.value } : { username: e.target.value } })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              SFTP Password {values._id && <span>(password blank for security)</span>}
                            </label>
                            <input
                              value={values.details?.password}
                              onChange={(e) => setValues({ ...values, details: values.details ? { ...values.details, password: e.target.value } : { password: e.target.value } })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              Path
                            </label>
                            <input
                              value={values.details?.path}
                              onChange={(e) => setValues({ ...values, details: values.details ? { ...values.details, path: e.target.value } : { path: e.target.value } })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </>}
                    </div>
                    <button
                      onClick={() => close()}
                      type="button"
                      className="mr-5 rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-blue-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-50 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => { submit(values); close() }}
                      type="button"
                      className=" rounded-md mt-5 border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
