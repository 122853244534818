import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import { useParams } from 'react-router-dom';
import { FileUpload } from './components/file-upload';
import Button from './components/button';
import Confirm from './components/confirm';

const objectSingle = "step";
const objectMulti = "steps";

export default function Steps() {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/config/${objectSingle}/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config/${objectMulti}/${params.id}`).then(x => {
        setData(x);
        setDeleteId(null)
      });
    });
  }

  const params = useParams()

  const submit = (values) => {
    api(`${process.env.REACT_APP_API_URL}/config/${objectSingle}/${params.id}`, { [objectSingle]: values }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config/${objectMulti}/${params.id}`).then(x => {
        setData(x);
      });
    });
  }
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/config/${objectMulti}/${params.id}`).then(x => {
      setData(x);
    });
  }, [])

  const updateOrder = (fromIndex, toIndex) => {
    let tempSteps = [...data];
    if (toIndex > data.length - 1) toIndex = data.length - 1;
    var element = tempSteps[fromIndex];
    tempSteps.splice(fromIndex, 1);
    tempSteps.splice(toIndex, 0, element);
    api(`${process.env.REACT_APP_API_URL}/config/order-steps`, { steps: tempSteps }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/config/${objectMulti}/${params.id}`).then(x => {
        setData(x);
      });
    });
  }

  return (
    <>
      <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
      {edit && <EditModal close={() => setEdit(null)} submit={submit} edit={edit}></EditModal>}
      <div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto"><Button className="mb-5" link="/admin/config" text="Back"></Button></div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div>
            <h2 className="capitalize flex-1 mx-auto text-lg font-medium leading-6 text-zinc-900 ">
              {objectMulti}
            </h2>
            <p className="text-sm text-neutral-500">These are the steps your employee will go through when onboarding. They are specific to this configuration and can be a questionnaire, document, video, or photo upload.</p>

          </div>
          <div className="w-96">
            <button
              onClick={() => setEdit({ name: '' })}
              type="button"
              className="float-right capitalize inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Add {objectSingle}
            </button>
          </div>


        </div>

        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              <Table data={data.sort((a, b) => a.orderIndex - b.orderIndex).map((x, i) => {
                return {
                  ...x,
                  edit: <span className="cursor-pointer underline" onClick={() => setEdit(x)}>Edit</span>,
                  deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>,
                  typeName: x.type == "0" ? 'Questionnaire' : x.type == "1" ? 'PDF' : x.type == "2" ? 'Video' : x.type == "3" ? 'Photo Upload' : '',
                  orderIndex: <input key={'x_' + x._id} onBlur={(e) => updateOrder(i, e.target.value)} className="rounded-md w-20 text-xs border border-neutral-200" type="number" defaultValue={i}></input>
                }
              })} columns={[
                {
                  Header: 'Order',
                  accessor: 'orderIndex',
                },
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'Type',
                  accessor: 'typeName',
                },
                {
                  Header: 'Edit',
                  accessor: 'edit',
                },
                {
                  Header: 'Delete',
                  accessor: 'deleteCell',
                },
              ]}></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditModal = ({ close, submit, edit }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    if (edit) {
      setValues(edit)
    }
  }, [])

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="w-full">
                  <h1 className="capitalize text-lg font-semibold text-neutral-900 text-center">
                    Manage {objectSingle}
                  </h1>
                  <div className="py-10 px-4 sm:px-6">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Name
                          </label>
                          <input
                            value={values.name}
                            onChange={(e) => setValues({ ...values, name: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />

                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label className="block text-sm font-medium text-zinc-700">
                          Type
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <select
                            value={values.type}
                            onChange={(e) => setValues({ ...values, type: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                          >
                            <option value=""></option>
                            <option value="0">Questionnaire</option>
                            <option value="1">View PDF</option>
                            <option value="2">View Video</option>
                            <option value="3">File Upload</option>
                          </select>
                        </div>
                      </div>

                      {values.type == "0" && <>
                        {values.questions?.map((x, index) => <>
                          <div className="font-bold text-xl">Question {index + 1}</div>
                          <div className="sm:col-span-6">
                            <div>
                              <label className="block text-sm font-medium text-zinc-700 mt-2">
                                Question Title
                              </label>
                              <input
                                value={x.title}
                                onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, title: e.target.value } : y) })}
                                type="text"
                                className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                              />

                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <div>
                              <label className="block text-sm font-medium text-zinc-700 mt-2">
                                Question Details
                              </label>
                              <input
                                value={x.details}
                                onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, details: e.target.value } : y) })}
                                type="text"
                                className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                              />

                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <div>
                              <label className="block text-sm font-medium text-zinc-700 mt-2">
                                Response Type
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <select
                                  value={x.responseType}
                                  onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, responseType: e.target.value } : y) })}
                                  type="text"
                                  className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                >
                                  <option value=""></option>
                                  <option value="yesno">Yes/No</option>
                                  <option value="freetext">Free Text</option>
                                  <option value="date">Date</option>
                                  <option value="select">Selection</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {x.responseType == "select" && <>
                            {!x.responseOptions && !x.responseOptions?.length && <button
                              onClick={() => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, responseOptions: x.responseOptions ? x.responseOptions.concat([{}]) : [{}] } : y) })}
                              type="button"
                              className="rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-gray-800 shadow-sm hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-zinc-50 focus:ring-offset-2"
                            >
                              Add Option
                            </button>}
                            {x.responseOptions?.map((option, optionIndex) => <>
                              <div className="sm:col-span-3">
                                <div>
                                  <label className="block text-sm font-medium text-zinc-700 mt-2">
                                    Option Text
                                  </label>
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      value={option.text}
                                      onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, responseOptions: x.responseOptions.map((z, j) => j === optionIndex ? { ...z, text: e.target.value } : z) } : y) })}
                                      type="text"
                                      className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                    >
                                    </input>
                                  </div>
                                </div>
                              </div>
                              <div className="sm:col-span-2">
                                <div>
                                  <label className="block text-sm font-medium text-zinc-700 mt-2">
                                    Option Value
                                  </label>
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      value={option.value}
                                      onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, responseOptions: x.responseOptions.map((z, j) => j === optionIndex ? { ...z, value: e.target.value } : z) } : y) })}
                                      type="text"
                                      className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                    >
                                    </input>
                                  </div>
                                </div>
                              </div>
                              {optionIndex == x.responseOptions.length - 1 && <div className="sm:col-span-1">

                                <button
                                  onClick={() => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, responseOptions: x.responseOptions ? x.responseOptions.concat([{}]) : [{}] } : y) })}
                                  type="button"
                                  className="rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-gray-800 shadow-sm hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-zinc-50 focus:ring-offset-2"
                                >
                                  Add Option
                                </button>

                              </div>}
                            </>)}

                          </>}
                          <div className="sm:col-span-3">
                            <div>
                              <label className="block text-sm font-medium text-zinc-700 mt-2">
                                Required
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <select
                                  value={x.required}
                                  onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, required: e.target.value } : y) })}
                                  type="text"
                                  className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                >
                                  <option value=""></option>
                                  <option value="no">Not Required</option>
                                  <option value="yes">Required </option>
                                  <option value="yes-answer-required">'Yes' Answer Required</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <div>
                              <label className="block text-sm font-medium text-zinc-700 mt-2">
                                Send With Webhook
                              </label>
                              <div className="mt-1 flex ">
                                <input
                                  checked={x.sendWithWebhook}
                                  onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, sendWithWebhook: e.target.checked } : y) })}
                                  type="checkbox"
                                  className="block rounded-md shadow-sm rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                >
                                </input>
                              </div>
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            {x.sendWithWebhook && <div>
                              <label className="block text-sm font-medium text-zinc-700 mt-2">
                                Data Id (used to identify this data in any webhooks that are sent)
                              </label>
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  value={x.dataId}
                                  onChange={(e) => setValues({ ...values, questions: values.questions.map((y, i) => i === index ? { ...y, dataId: e.target.value } : y) })}
                                  type="text"
                                  className="block w-full min-w-0 flex-1 rounded-md border-zinc-300 focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                >
                                </input>
                              </div>
                            </div>}
                          </div>
                          <hr className="sm:col-span-6" />
                        </>)}

                        <div className="sm:col-span-6">
                          <button
                            onClick={() => setValues({ ...values, questions: values.questions ? values.questions.concat([{ responseOptions: [{}] }]) : [{}] })}
                            type="button"
                            className="rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-gray-800 shadow-sm hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-zinc-50 focus:ring-offset-2"
                          >
                            Add Question
                          </button>
                        </div>
                      </>}

                      {values.type == "1" && <>
                        <FileUpload success={(x) => setValues({ ...values, contentLocation: x.filesNames[0] })}></FileUpload>
                      </>}

                      {values.type == "2" && <>
                        <div className="sm:col-span-6">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              Video URL
                            </label>
                            <input
                              value={values.contentLocation}
                              onChange={(e) => setValues({ ...values, contentLocation: e.target.value })}
                              type="text"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />

                          </div>
                        </div>
                      </>}

                      {values.type == "3" && <>
                        <div className="sm:col-span-6">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              File Upload Instructions
                            </label>
                            <textarea rows="3"
                              value={values.photoUploadInstructions}
                              onChange={(e) => setValues({ ...values, photoUploadInstructions: e.target.value })}
                              type="text"
                              placeholder="Instructions for the employee...."
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />

                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              Min Files
                            </label>
                            <input
                              value={values.minFiles}
                              onChange={(e) => setValues({ ...values, minFiles: e.target.value })}
                              type="number"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />

                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 mt-2">
                              Max Files
                            </label>
                            <input
                              value={values.maxFiles}
                              onChange={(e) => setValues({ ...values, maxFiles: e.target.value })}
                              type="number"
                              className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </>}

                    </div>
                    <button
                      onClick={() => close()}
                      type="button"
                      className="mr-5 rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-blue-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-50 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => { submit(values); close() }}
                      type="button"
                      className=" rounded-md mt-5 border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
