import { Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./login";
import Logout from "./logout";
import RequestReset from "./request-reset";
import ResetPassword from "./reset-password";
import AdminLayout from "./admin-layout";
import Landing from "./landing";
import MasterAdminUsers from "./master-admin-users";
import MasterAdminCompanies from "./master-admin-companies";
import Configurations from "./admin-configs";
import MasterAdminLayout from "./master-admin-layout";
import AdminLocations from "./admin-locations";
import Steps from "./admin-steps";
import AdminInductions from "./admin-inductions";
import Induction from "./induction";
import SubmitSteps from "./admin-submit-steps";
import AdminViewInduction from "./admin-view-induction";
import AdminWebhooks from "./admin-webhooks";
import AdminUsers from "./admin-users";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/induction/:id" element={<Induction />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />

      <Route path='/admin' element={<AdminLayout />}>
        <Route path="/admin" element={<AdminInductions />} />
        <Route path="/admin/locations" element={<AdminLocations />} />
        <Route path="/admin/steps/:id" element={<Steps />} />
        <Route path="/admin/submit-steps/:id" element={<SubmitSteps />} />
        <Route path="/admin/config" element={<Configurations />} />
        <Route path="/admin/induction/:id/:config" element={<AdminViewInduction />} />
        <Route path="/admin/webhooks" element={<AdminWebhooks />} />
        <Route path="/admin/users" element={<AdminUsers />} />
      </Route>

      <Route path='/system-admin' element={<MasterAdminLayout />}>
        <Route path="/system-admin" element={<MasterAdminUsers />} />
        <Route path="/system-admin/companies" element={<MasterAdminCompanies />} />
      </Route>
    </Routes>
  )
}
